import { withAuthenticationRequired } from '@auth0/auth0-react'
import React from 'react'
import { PageLoader } from 'cufc-ui'

interface AuthenticationGuardProps {
  component: React.FC<unknown> // Specify the type of component expected
}

export const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({
  component,
}) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    ),
  })

  return <Component />
}
