import React from 'react'
import { AboutUs, SmallHero } from 'cufc-ui'
import { gearListLink } from '../../constants'

const AboutUsComponent = ({ onNavigationClick }) => {
  return (
    <div className="bg-white">
      <>
        <SmallHero pageTitle="About Us" />
        <AboutUs
          onNavigationClick={onNavigationClick}
          gearRecommendationsLink={gearListLink}
        />
      </>
    </div>
  )
}

export default AboutUsComponent
