import { MemberModel } from '../models/Member'
import { AxiosRequestConfig } from 'axios'
import { callExternalApi } from './external-api.service'

const apiURL = process.env.REACT_APP_API_SERVER_URL

export const getMembersAPI = async (accessToken: string) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/members/getAll`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }
  return await callExternalApi({ config })
}

export const getMembersForAttendance = async (accessToken: string) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/members/getAllForAttendance`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }
  return await callExternalApi({ config })
}

export const getMemberByIdAPI = async (
  id: string | undefined,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/members/getOne/${id}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }
  return await callExternalApi({ config })
}

export const addMemberAPI = async (
  member: MemberModel,
  accessToken: string,
) => {
  try {
    const config: AxiosRequestConfig = {
      url: apiURL + `/api/members/add`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      data: member,
    }
    return await callExternalApi({ config })
  } catch (error) {
    console.log('Oops ' + error)
  }
}

export const deleteMemberAPI = async (
  memberId: string,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/members/delete/${memberId}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }
  return await callExternalApi({ config })
}

export const updateMemberAPI = async (
  member: MemberModel,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/members/update/${member._id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: member,
  }

  return await callExternalApi({ config })
}

export const checkInMemberAPI = async (_id: string, accessToken: string) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/attendance/checkIn`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      userId: _id,
    },
  }
  return await callExternalApi({ config })
}

export const getDailyAttendanceAggregateAPI = async () => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/attendance/dailyAggregate`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return await callExternalApi({ config })
}
