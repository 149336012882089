import React, { useState, useEffect } from 'react'
import { SciotoOpen2024SignUp } from 'cufc-ui'

const apiURL = process.env.REACT_APP_API_SERVER_URL

const SciotoOpen2024Component = () => {
  const [slotsFilled, setSlotsFilled] = useState({
    longsword: 0,
    saber: 0,
    mg: 0,
  })

  useEffect(() => {
    const fetchSlotsFilled = async () => {
      try {
        const response = await fetch(`${apiURL}/api/sciotoOpen/getSlotsFilled`)
        if (!response.ok) {
          throw new Error(
            `Failed to fetch slots filled: ${response.statusText}`,
          )
        }
        const data = await response.json()
        setSlotsFilled(data)
      } catch (error) {
        console.error('Error fetching slots filled:', error)
      }
    }

    fetchSlotsFilled()
  }, [])

  const submitFormData = async (formData) => {
    try {
      await sendEmail(formData)
      await createPaymentLink(formData.events)
    } catch (error) {
      console.error('Error processing form data:', error)
    }
  }

  const sendEmail = async (formData) => {
    const response = await fetch(
      `${apiURL}/api/sciotoOpen/sendEmailSciotoOpen`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      },
    )

    if (!response.ok) {
      throw new Error(`Failed to send email: ${response.statusText}`)
    }
    console.log('Email sent successfully!')
  }

  const createPaymentLink = async (events) => {
    const name = generatePaymentName(events)
    const amount = calculateTotalPrice(events)

    const response = await fetch(`${apiURL}/api/square/createPaymentLink`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, amount }),
    })

    if (!response.ok) {
      throw new Error(`Failed to create payment link: ${response.statusText}`)
    }

    const paymentData = await response.json()
    window.location.href = paymentData.url // Redirect to payment link
  }

  const generatePaymentName = (events) => {
    const eventNames = events.join(', ')
    return `Scioto Open 2024 Registration - ${eventNames}`
  }

  const calculateTotalPrice = (events) => {
    const eventPrices = {
      'Open Longsword': 2000,
      'Open Saber': 2000,
      'Marginalised Genders Longsword': 0,
    }

    return events.reduce(
      (total, event) => total + (eventPrices[event] || 0),
      4000,
    ) // Base price in cents ($30.00)
  }

  return (
    <div className="bg-white">
      <SciotoOpen2024SignUp
        onSubmit={submitFormData}
        slotsFilled={slotsFilled}
      />
    </div>
  )
}

export default SciotoOpen2024Component
