import { MemberModel } from '../../models/Member'
import { useEffect, useState } from 'react'
import {
  deleteMemberAPI,
  getMembersAPI,
  updateMemberAPI,
  getDailyAttendanceAggregateAPI,
  addMemberAPI,
} from '../../services/api'
import { AdminPage, PageLoader } from 'cufc-ui'
import { useAuth0 } from '@auth0/auth0-react'
import { DailyAttendanceAggregateCount } from '../../models/AttendanceAggregate'

const getMembers = async (getAccessTokenSilently, setMembers, setLoading) => {
  try {
    const accessToken = await getAccessTokenSilently()
    const res = await getMembersAPI(accessToken)
    if (res.data) {
      setMembers(res.data)
    }
  } catch (error) {
    console.error('Error fetching members:', error)
  } finally {
    setLoading(false)
  }
}

const AdminView = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [members, setMembers] = useState<MemberModel[]>([])
  const [isLoading, setLoading] = useState(true)
  const [dailyAttendanceAggregateData, setDailyAttendanceAggregateData] =
    useState<DailyAttendanceAggregateCount[]>([])

  const getDailyAggregateAttendanceData = async () => {
    try {
      const res = await getDailyAttendanceAggregateAPI()
      if (res.data) {
        setDailyAttendanceAggregateData(res.data)
      }
    } catch (error) {
      console.error('Error fetching attendance:', error)
    }
  }

  const handleOnUpdateMember = async (updatedMemberData: MemberModel) => {
    setLoading(true)
    const accessToken = await getAccessTokenSilently()
    await updateMemberAPI(updatedMemberData, accessToken)
    await getMembers(getAccessTokenSilently, setMembers, setLoading)
  }

  const handleOnDeleteMember = async (deletedMemberId: string) => {
    setLoading(true)
    const accessToken = await getAccessTokenSilently()
    await deleteMemberAPI(deletedMemberId, accessToken)
    await getMembers(getAccessTokenSilently, setMembers, setLoading)
  }

  const handleOnAddMember = async (updatedMemberData: MemberModel) => {
    setLoading(true)
    console.log(updatedMemberData)
    const accessToken = await getAccessTokenSilently()
    await addMemberAPI(updatedMemberData, accessToken)
    await getMembers(getAccessTokenSilently, setMembers, setLoading)
  }

  useEffect(() => {
    const fetchData = async () => {
      await getMembers(getAccessTokenSilently, setMembers, setLoading)
      await getDailyAggregateAttendanceData()
    }
    fetchData()
  }, [getAccessTokenSilently])

  return isLoading ? (
    <PageLoader />
  ) : (
    <AdminPage
      members={members}
      onUpdateMember={handleOnUpdateMember}
      onDeleteMember={handleOnDeleteMember}
      onAddMember={handleOnAddMember}
      attendanceAggregate={dailyAttendanceAggregateData}
    />
  )
}

export default AdminView
