import React from 'react'
import { ContactUs } from 'cufc-ui'
import { facebookLink, instagramLink } from '../../constants'

const apiURL = process.env.REACT_APP_API_SERVER_URL

const ContactComponent = () => {
  const submitEmail = async (formData) => {
    try {
      const response = await fetch(apiURL + '/api/email/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      if (response.ok) {
        console.log('Email sent successfully!')
      } else {
        console.error('Failed to send email:', response.statusText)
      }
    } catch (error) {
      console.error('Error sending email:', error)
    }
  }

  return (
    <div className="bg-white">
      <ContactUs
        onSubmit={submitEmail}
        instagramLink={instagramLink}
        facebookLink={facebookLink}
      />
    </div>
  )
}

export default ContactComponent
