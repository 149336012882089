import React from 'react'
import { AboutNugget } from 'cufc-ui'

const IntroClassComponent = ({ onNavigationClick }) => {
  return (
    <div className="bg-white">
      <>
        <AboutNugget onNavigationClick={onNavigationClick} />
      </>
    </div>
  )
}

export default IntroClassComponent
